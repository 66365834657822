h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: bold;
}

.full-width {
    max-width: 100%;
    width: 100%;
}

.img-responsive {
    max-width: 100%;
    width: auto;
}

.text-left {
    text-align: left;
}

.text-right {
    text-align: right;
}

.text-center {
    text-align: center;
}

.section {
    padding: 5em 0;
}

.profile-picture {
    max-width: 250px;
    margin: 0 0 1em 0;
    display: inline-block;
}

body .ant-layout {
    background-color: #bae7ffa6;
}

body .ant-layout-footer {
    background-color: transparent;
}

body {
    font-family: "Open Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.box-shadow {
    background-blend-mode: multiply;
    padding: 1em;
    border-radius: 0.3em;
    box-shadow: 0 0.5em 1.7em #2d2d2d85;
    background-color: #ffffff !important;
}

.topNavigation {
    display: inline-block;
}

.ant-layout-footer a img {
    display: inline;
    width: 100%;
}

.ant-layout-footer a {
    width: 1.5em;
    display: inline-block;
    margin: 0 0.5em;
}

.content-wrapper {
    height: 100vh;
    margin-bottom: 0;
}

.content-wrapper > * {
    z-index: 2;
}

.content-wrapper::after {
    background-color: #40a9ff30;
    content: "";
    display: inline-block;
    z-index: 1;
    position: fixed;
    height: 30vh;
    width: 110%;
    top: 50%;
    left: -5%;
    transform: rotate(-3deg) translateY(-50%);
}

.ant-layout-footer.responsive-footer {
    text-align: center;
    position: fixed;
    bottom: 0px;
    z-index: 1;
    width: 100%;
    font-size: 0.6rem;
}

@media screen and (max-height: 700px) {
    .ant-layout-footer.responsive-footer {
        position: relative;
    }
}
